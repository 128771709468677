/*Main styling page*/

/*Colors*/
:root{
  --white: white;
  --mainRed: #bf1315;
  --offWhite: #a38787;
  --orange: #FF9505;
}

body{
  margin: 0;
  padding: 0;
  background-color: var(--white);
}

p{
  font-family: "Roboto", sans-serif;
}


a{
  text-decoration: none;
  color: var(--offWhite);
}

a svg {
  fill: var(--offWhite);
}

a svg:hover{
  fill: var(--mainRed);
}

.homeImage {
  width: 300px;
  border-radius: 200px;
  
}

.aboutImage{
  border-radius: 15px;
}

.homeImageMobile {
  max-width: 92vw;
  border-radius: 15px;
  position: absolute;
  z-index: -1;
}

.cardLine {
  border: 1px solid rgb(235, 235, 235);
  margin: 0 10px 0 10px;
}

.logoStyle {
  width: 70px;
  margin-left: 10px;
}

.redLine {
  background-color: var(--mainRed);
  width: 50px;
  height: 5px;
  margin-bottom: 10px;
}

.blackLine {
  background-color: black;
  width: 70px;
  height: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.spacerDiv{
  margin-top: 40vh;
}





/*!
 * Hover.css (http://ianlunn.github.io/Hover/)
 * Version: 2.3.2
 * Author: Ian Lunn @IanLunn
 * Author URL: http://ianlunn.co.uk/
 * Github: https://github.com/IanLunn/Hover

 * Hover.css Copyright Ian Lunn 2017. Generated with Sass.
 */

 /* Underline From Left */
 .hvr-underline-from-left {
   display: inline-block;
   vertical-align: middle;
   -webkit-transform: perspective(1px) translateZ(0);
   transform: perspective(1px) translateZ(0);
   box-shadow: 0 0 1px rgba(0, 0, 0, 0);
   position: relative;
   overflow: hidden;
 }

 .hvr-underline-from-left:before {
   content: "";
   position: absolute;
   z-index: -1;
   left: 0;
   right: 100%;
   bottom: 0;
   background: var(--mainRed);
   height: 4px;
   -webkit-transition-property: right;
   transition-property: right;
   -webkit-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -webkit-transition-timing-function: ease-out;
   transition-timing-function: ease-out;
 }

 .hvr-underline-from-left:hover:before,
 .hvr-underline-from-left:focus:before,
 .hvr-underline-from-left:active:before {
   right: 0;
 }

/*Hover Grow*/
 .hvr-grow {
   display: inline-block;
   vertical-align: middle;
   -webkit-transform: perspective(1px) translateZ(0);
   transform: perspective(1px) translateZ(0);
   box-shadow: 0 0 1px rgba(0, 0, 0, 0);
   -webkit-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -webkit-transition-property: transform, fill;
   transition-property: transform, fill;
 }

 .hvr-grow:hover,
 .hvr-grow:focus,
 .hvr-grow:active {
   -webkit-transform: scale(1.1);
   transform: scale(1.1);
 }

 /* Sweep To Top */
 .hvr-sweep-to-top {
   display: inline-block;
   vertical-align: middle;
   -webkit-transform: perspective(1px) translateZ(0);
   transform: perspective(1px) translateZ(0);
   box-shadow: 0 0 1px rgba(0, 0, 0, 0);
   position: relative;
   -webkit-transition-property: color;
   transition-property: color;
   -webkit-transition-duration: 0.3s;
   transition-duration: 0.3s;
   padding-left: 4px;
   padding-right: 4px;
   border-bottom: 2px solid var(--mainRed);
   color: black;
 }

 .hvr-sweep-to-top:before {
   content: "";
   position: absolute;
   z-index: -1;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: var(--mainRed);
   -webkit-transform: scaleY(0);
   transform: scaleY(0);
   -webkit-transform-origin: 50% 100%;
   transform-origin: 50% 100%;
   -webkit-transition-property: transform;
   transition-property: transform;
   -webkit-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -webkit-transition-timing-function: ease-out;
   transition-timing-function: ease-out;
 }

 .hvr-sweep-to-top:hover,
 .hvr-sweep-to-top:focus,
 .hvr-sweep-to-top:active {
   color: white;
 }

 .hvr-sweep-to-top:hover:before,
 .hvr-sweep-to-top:focus:before,
 .hvr-sweep-to-top:active:before {
   -webkit-transform: scaleY(1);
   transform: scaleY(1);
 }

 /*Other Classes*/


